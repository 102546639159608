@import './variables.css';

*, *::before, *::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background: var(--color-body-background);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* contain elements to their container width */
code, pre {
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

a,
a:visited {
  color: inherit;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

.dead {
  padding: 2px 0 5px;
  font-size: calc(var(--fs-body) - 0.10rem);
  font-style: italic;
  color: var(--color-subtitle1);
}
.dead::before {
  content: '[';
}
.dead::after {
  content: ']';
}