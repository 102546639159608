.container {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  row-gap: 10px;
}

.content {
  justify-self: center;
  align-self: flex-start;
  width: 100%;
  height: 100%;
  display: grid;
  align-items: flex-start;
  align-content: flex-start;
}

.sidebar {
  display: grid;
  align-items: flex-start;
}


@media screen and (min-width: 640px) {
  .container {
    row-gap: 20px;
  }
  .content {
    width: clamp(300px, 80vw, 1000px);
  }
}

@media screen and (min-width: 960px) {
  .content {
    grid-template-columns: 1fr 300px;
    column-gap: 25px;
  }
}