@import './../variables.css';

.comment {
  --side-toggle-width: 40px;
  --children-offset-x: -15px;
  --comment-column-gap: 3px;
  display: grid;
  grid-template-columns: var(--side-toggle-width) 1fr;
  column-gap: var(--comment-column-gap);
}
.comment:not(:first-of-type),
.children:not(:empty),
.children > :not(.comment):not(.linkButton) {
  margin-top: 15px;
}
.commentMinimized {
  composes: comment;
  grid-template-columns: calc(var(--side-toggle-width) * 2) 1fr;
}
.commentMinimized .contentBody {
  display: none;
}
.commentMinimized .sidePanel {
  grid-template-columns: 40px auto;
}
.commentMinimized .maximizeToggle {
  display: inline-block;
}


.sidePanel {
  display: grid;
  grid-template-rows: var(--user-icon-size) 1fr;
  justify-items: center;
  align-items: center;
  row-gap: 6px;
}
.maximizeToggle {
  padding: 4px;
  border-radius: var(--corner-rounding);
  width: 23px !important;
  height: 23px;
  color: var(--color-accent);
  display: none;
  transform: scaleX(-1);
  transition: all 100ms ease-out;
  cursor: pointer;
}
.maximizeToggle:hover {
  background: var(--color-button-hover);
}
.maximizeToggle:active {
  transform: scaleX(-1) scale(0.95);
}

.minimizeToggle {
  width: 12px;
  height: 100%;
  display: grid;
  justify-items: center;
  cursor: pointer;
}
.minimizeToggle::after {
  content: ' ';
  border-right: 2px solid rgba(var(--body-background-rgb), 0.5);
  transition: border-color 100ms ease-out;
}
.minimizeToggle:not(:only-child):hover::after {
  border-right-color: var(--color-accent);
}
.minimizeToggle:only-child::after {
  border-right-style: dashed;
}
.minimizeToggle:only-child {
  grid-row: 1 / 3;
  cursor: unset;
}

.content {
  display: grid;
  grid-template-rows: var(--user-icon-size) 1fr;
}
.content > header {
  font-size: var(--fs-subtitle);
  color: var(--color-subtitle1);
  display: flex;
  align-items: center;
}
.userId,
.userId:visited {
  font-weight: bold;
  color: var(--color-dark);
}
.accentedContent {
  composes: content;
  padding: 4px 8px 0;
  border-radius: var(--corner-rounding);
  background: rgba(var(--accent-rgb), 0.15);
}

.children {
  margin-left: calc(var(--children-offset-x) - var(--comment-column-gap));
}
.children:empty {
  margin-top: 5px;
}

.linkButton {
  composes: linkButton from "./Button.module.css";
  margin-bottom: 5px;
  font-size: var(--fs-subtitle);
}
.linkButton,
.children > :not(.comment) {
  margin-left: calc(calc(var(--children-offset-x) * -1) + var(--comment-column-gap));
}