@import './../variables.css';

.userView {
  composes: content from "./../App.module.css";
  row-gap: 10px;
}

.userContent {
  composes: catalog from "./../views/CatalogView.module.css";
}
.button {
  composes: loadMoreButton from "./../components/Button.module.css";
}

.sidebar {
  composes: sidebar from "./../App.module.css";
  grid-row: 1;
}
.userProfile {
  --container-corner: 0;
  --icon-offset-y: 25px;
  border-radius: var(--container-corner);
  background: var(--color-content-background);
}
.userProfile > header {
  padding: 0 10px;
  border-radius: var(--container-corner) var(--container-corner) 0 0;
  background: var(--color-accent);
  display: flex;
}
.userIcon {
  padding: 4px;
  border-radius: var(--corner-rounding);
  background: var(--color-content-background);
  transform: translateY(var(--icon-offset-y));
}
.userProfile > main {
  margin-top: var(--icon-offset-y);
  padding: 0 15px 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.userProfile > main > :last-child,
.userId,
.about {
  grid-column: 1 / -1;
}
.userProfile > main > a:last-child {
  margin-top: 35px;
}
.userId {
  margin-bottom: 15px;
  font-size: var(--fs-subtitle);
}
.text {
  font-size: calc(var(--fs-subtitle) + 0.05rem);
  color: var(--color-subtitle1);
}
.textIcon {
  margin-right: 5px;
  color: var(--color-accent);
}
.heading {
  margin-bottom: 3px;
  font-size: calc(var(--fs-subtitle) + 0.15rem);
  font-weight: bold;
}
.about {
  margin-top: 15px;
  display: grid;
  row-gap: 3px;
}


@media screen and (min-width: 640px) {
  .userView {
    row-gap: 20px;
  }
  .userProfile {
    --container-corner: var(--corner-rounding);
  }
}
@media screen and (min-width: 960px) {
  .content {
    grid-template-columns: 1fr 300px;
    column-gap: 25px;
  }
  .sidebar {
    grid-row: unset;
  }
}