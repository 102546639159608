.storyView {
  composes: content from "./../App.module.css";
  composes: catalog from "./../views/CatalogView.module.css";
  grid-template-columns: unset;
}

.commentList {
  padding: 15px 10px;
  background: var(--color-content-background);
  display: grid;
  align-items: flex-start;
}
.commentList > p  {
  margin-top: 10px;
}

.button {
  composes: loadMoreButton from "./../components/Button.module.css";
  margin-top: 15px;
  padding: 6px;
}

@media screen and (min-width: 640px) {
  .commentList  {
    border-radius: var(--corner-rounding);
  }
}