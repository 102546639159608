@import './../variables.css';

.button {
  padding: 5px 7px;
  border: none;
  border-radius: var(--corner-rounding);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  transition: all 100ms ease-out;
  cursor: pointer;
}
.button:visited {
  color: inherit;
}
.button:hover {
  background: var(--color-button-hover);
  text-decoration: none;
}
.button:active {
  transform: scale(0.95);
}

/* fontawesome icon */
.buttonIcon {
  width: var(--icon-size) !important;
  height: var(--icon-size);
}

/* button with accent color as background */
.accentedButton {
  composes: button;
  background: var(--color-accent);
  color: var(--color-light);
}
.accentedButton:visited {
  color: var(--color-light);
}
.accentedButton:hover {
  background-color: rgba(var(--accent-rgb), 0.85);
}

/* load more items button */
.loadMoreButton {
  composes: accentedButton;
  padding: 8px;
}

/* link button - link styled button */
.linkButton {
  composes: button;
  padding: 0;
  border: none;
  background: none;
  color: var(--color-accent);
  gap: 0;
}
.linkButton:hover {
  background: none;
  text-decoration: underline;
}