:root {
  --corner-rounding: 4px;
  --accent-rgb: 255, 102, 0;
  --body-background-rgb: 218, 224, 230;
  --content-background-rgb: 255, 255, 255;
  --color-accent: rgb(var(--accent-rgb));
  --color-body-background: rgb(var(--body-background-rgb));
  --color-content-background: rgb(var(--content-background-rgb));
  --color-dark: rgb(34, 34, 34);
  --color-light: rgb(250, 250, 250);

  /* buttons */
  --icon-size: 18px;
  --color-button: rgb(135, 138, 140);
  --color-button-hover: rgba(26, 26, 27, 0.1);
  --color-button-active: rgba(135, 138, 140, 0.1);

  /* text */
  --fs-title: 1.2rem;
  --fs-subtitle: 0.75rem;
  --fs-body: 1rem;
  --color-subtitle1: rgb(135, 138, 140);
  --color-subtitle2: rgb(124, 124, 124);

  --user-icon-size: 28px;
}