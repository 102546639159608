@import './../variables.css';

.header {
  padding: 8px 15px;
  background: white;
  position: sticky;
  top: 0;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}
.logo:hover {
  text-decoration: none;
}
.logoImage {
  width: 1.5625rem;
  height: 1.5625rem;
}
.logoText {
  font-size: 1.25rem;
  font-weight: bold;
}


.button {
  composes: linkButton from "./../components/Button.module.css";
  color: var(--color-dark);
}
.button:hover,
.button:active {
  color: var(--color-accent);
}
.buttonIcon {
  width: 22px !important;
  height: 22px;
}


@media screen and (min-width: 640px) {
  .header  {
    padding: 8px 20px;
  }

  .logoImage {
    width: 1.875rem;
    height: 1.875rem;
  }
  .logoText {
    font-size: 1.5rem;
    font-weight: bold;
  }

  .buttonIcon {
    width: 24px !important;
    height: 24px;
  }
}